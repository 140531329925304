import React from "react";
// import {
//   offers_light_about,
//   offers_txt_buy_nft_middle,
// } from './Assets/Images/Index';

import buysorekt from "./Assets/Images/AboutPage/ba.png";

import rest from "./Assets/Images/AboutPage/bap.svg";
import Button from "./Button";
import Footer from "./Footer";
import NavBar from "./NavBar";
import "./Styles/BuyNftMiddle.css";

const BuyNftMiddle = () => {
  return (
    <div className="buy-nft-middle">
      <NavBar />

      <img
        className="buy-nft-middle_banner"
        src={buysorekt}
        alt="offers_light_about"
    
      />
        <div className="buy-nft-middle_mint" style={{paddingTop:30,paddingBottom:30,marginLeft:-30}}>
        <Button
          txt="Mint your NFT"
          grbtnh="87.5px"
          btnh="83.28px"
          txtt="28px"
          txb="0"
          txtr="0"
          txtl="68px"
          txtsz="17.726px"
          txtsh="none"
          txtf="'Avenir', sans-serif"
          txtColor="#000000"
          name="mint_nft"
          onClick={() => {
            window.open('https://mint.sorekt.wtf/');
          }}
        />
      </div>
         <img
        className="buy-nft-middle_bannerr"
        src={rest}
        alt="offers_light_about"
       
      />
      {/* <img
        className='buy-nft-middle_banner'
        src={offers_light_about}
        alt='offers_light_about'
      />
      <div className='buy-nft-middle_offers_txt'>
        <img src={offers_txt_buy_nft_middle} alt='offers_txt_buy_nft_middle' />
      </div> */}
      <div className="buy-nft-middle_mint">
        <Button
          txt="Mint your NFT"
          grbtnh="87.5px"
          btnh="83.28px"
          txtt="28px"
          txb="0"
          txtr="0"
          txtl="68px"
          txtsz="17.726px"
          txtsh="none"
          txtf="'Avenir', sans-serif"
          txtColor="#000000"
          name="mint_nft"
          onClick={() => {
            window.open('https://mint.sorekt.wtf/');
          }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default BuyNftMiddle;
