import React, { useEffect, useState } from 'react';
import './Styles/AiEngineFree.css';
// import CONFIG from './Config.json';

import axios from 'axios';

import {
  // generated_1,
  // generated_2,
  home,
  home_dark,
  logo_ai,
  rotate_animation_ai,
  rotate_prompt_ai,
  mask_arrow_ai,
  head_rotate_transparent_ai,
  head_rotate_ai,
  SoRekt_Black_1,
  prompt_one_ai,
  prompt_two_ai,
  prompt_three_ai,
  prompt_four_ai,
  tile_meme_ai,
  tile_cover_ai,
  plus_ai_engine_free,
  loader_ai,
} from './Assets/Images/Index';
import SpinTokens from './SpinTokens';
import Footer from './Footer';
import PopUp from './PopUp';
// import { Configuration, OpenAIApi } from 'openai';
// import { web3Handler } from './ConnectionFunction';
import { NavLink } from 'react-router-dom';

const Inspression = () => {
  const [order, setOrder] = useState(['ori', 'ani', 'meme', 'new']);
  const [active, setActive] = useState('tile');
  const [tilePopUpTrigger, setTilePopUpTrigger] = useState(false);
  // const [isMeme, setIsMeme] = useState(false);
  const [memeText, setMemeText] = useState('');
  // const imagesSourceCover = [
  //   // generated_1,
  //   head_rotate_ai,
  //   head_rotate_ai,
  //   head_rotate_ai,
  //   head_rotate_ai,
  // ];
  const imagesSourcePrompt = [
    // generated_1,
    prompt_one_ai,
    prompt_two_ai,
    prompt_three_ai,
    prompt_four_ai,
  ];
  const imagesSourceAni = [
    // generated_1,
    prompt_one_ai,
    prompt_two_ai,
    prompt_three_ai,
    prompt_four_ai,
  ];
  const [isGenerateloading, setIsGenerateLoading] = useState(false);
  const [nftNummber] = useState();
  const [imageURL, setImageURL] = useState(tile_cover_ai);
  const [uploadedFile, setUploadedFile] = useState();
  const [dropBoxImageUrl, setDropBoxImageUrl] = useState(head_rotate_ai);
  const [generatedPromptURL, setGeneratedPromptURL] =
    useState(imagesSourcePrompt);
  const [generatedImageURL, setGeneratedImageURL] =
    useState(generatedPromptURL);
  const [generatedMemeText, setGeneratedMemeText] = useState([
    "Rekt! I'm sad",
    'You mad bro?',
    'Shoot! I failed the test',
    "That ain't no cool bruh!",
  ]);
  const [selectedPromptURL, setSelectedPromptURL] = useState(rotate_prompt_ai);
  const [selectedMemeText, setSelectedMemeText] = useState(
    generatedMemeText[1]
  );
  const [selectedMemeImage, setSelectedMemeImage] = useState(tile_meme_ai);
  const [selectedAnimatedImage, setSelectedAnimatedImage] =
    useState(rotate_animation_ai);
  // const [nftNumberList, setNftNumberList] = useState([]);
  //acc
  // const [provider, setProvider] = useState();
  // const [account, setAccount] = useState(null);
  // const [soRekt, setSoRekt] = useState({});
  // pop up
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msg, setMsg] = useState('');
  // const [, setNavigation] = useState('/');
  const [notYourNftPop, setNotYourNftPop] = useState(false);
  const [reviewMintedPop, setReviewMintedPop] = useState(false);
  const [dropImageDesc, setDropImageDesc] = useState('Cover');
  const [activeImage, setActiveImage] = useState('ori');
  const [nftGenerated, setNftGenerated] = useState(false);

  // const nftData = [
  //   {
  //     src: nft_1_ai,
  //     number: "SoRekt NFT #1000",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  //   {
  //     src: nft_2_ai,
  //     number: "SoRekt NFT #89",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  //   {
  //     src: nft_3_ai,
  //     number: "SoRekt NFT #21",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  //   {
  //     src: nft_4_ai,
  //     number: "SoRekt NFT #12",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  //   {
  //     src: nft_1_ai,
  //     number: "SoRekt NFT #1001",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  //   {
  //     src: nft_2_ai,
  //     number: "SoRekt NFT #90",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  //   {
  //     src: nft_3_ai,
  //     number: "SoRekt NFT #22",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  //   {
  //     src: nft_4_ai,
  //     number: "SoRekt NFT #13",
  //     openSea: "Opensea",
  //     etherScan: "Etherscan",
  //   },
  // ];

  function setFourImages(id) {
    if (id === 'tileOriginal' || id === 'ori') {
      setDropImageDesc('Cover');
      setActiveImage('ori');
      setGeneratedImageURL(generatedPromptURL);
      // setIsMeme(false);
    } else if (id === 'tilePrompt' || id === 'new') {
      setDropImageDesc('Prompt');
      setActiveImage('prompt');
      setGeneratedImageURL(generatedPromptURL);
      // setIsMeme(false);
    } else if (id === 'tileMeme' || id === 'meme') {
      setDropImageDesc('Meme');
      setActiveImage('meme');
      setGeneratedImageURL(generatedPromptURL);
      // setIsMeme(true);
    } else if (id === 'tileAnimation' || id === 'ani') {
      setDropImageDesc('Animation');
      setActiveImage('ani');
      setGeneratedImageURL(imagesSourceAni);
      // setIsMeme(false);
    }
  }

  const [tilePopUpContent, setTilePopUpContent] = useState('');
  function openTilePopUp(e) {
    let tileId = e.target.id;
    if (e.target.id === '') {
      tileId = e.target.parentNode.id;
    }
    if (tileId === 'tileMeme') {
      document.getElementById('inputForMeme').style.visibility = 'visible';
      document.getElementById('inputForMeme').style.opacity = '1';
    } else {
      document.getElementById('inputForMeme').style.visibility = 'hidden';
      document.getElementById('inputForMeme').style.opacity = '0';
    }

    if (tileId === 'tileMeme') {
      setTilePopUpContent(
        <div className='inspression_tile_pop' id='tilePopUp'>
          <p>{selectedMemeText}</p>
          {nftGenerated ? (
            ''
          ) : (
            <img
              className='inspression_tile_pop_meme'
              src={selectedMemeImage}
              alt='tile_meme_ai'
            />
          )}
        </div>
      );
    } else if (tileId === 'tileOriginal') {
      setTilePopUpContent(
        <div className='inspression_tile_pop' id='tilePopUp'>
          <img
            style={{
              width: '70px',
              height: '40x',
              marginTop: '5px',
            }}
            src={SoRekt_Black_1}
            alt='SoRekt_Black_1'
          />

          <img
            style={{
              width: '110px',
              height: '40px',
              marginTop: '-5px',
            }}
            src={logo_ai}
            alt='logo_ai'
          />
          <img
            style={{ width: '148px', height: '160px' }}
            src={imageURL}
            alt='origianl_view'
          />
        </div>
      );
    } else {
      setTilePopUpContent(
        <div className='inspression_tile_pop'>
          <img
            className='inspression_tile_pop_img'
            id='tilePopUp'
            src={e.target.src}
            alt='tilePopUp'
          />
        </div>
      );
    }
    // console.log(id);
    setTilePopUpTrigger(true);

    setFourImages(tileId);
  }

  function onClickOutsideTilePopUp(e) {
    let id = e.target.id;
    if (e.target.id === '') {
      id = e.target.parentNode.id;
    }

    if (id !== 'tilePopUp') {
      setTilePopUpTrigger(false);
    }
  }

  function arrayRotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }

  function rotateRight() {
    let temp = order;
    let i;
    // console.log('here');
    // console.log(temp);
    for (i in order) {
      // console.log(document.getElementById(temp[i]).className + ' ' + i);
      if (i === '0') {
        let t = temp[i];

        if (t === 'new') {
          document.getElementById(t).style.transform = 'translate(300px,0px)';
        } else if (t === 'ori') {
          document.getElementById(t).style.transform =
            'translate(150px, -20px)';
        } else if (t === 'ani') {
          document.getElementById(t).style.transform = 'translate(0, 0)';
        } else if (t === 'meme') {
          document.getElementById(t).style.transform = 'translate(150px, 20px)';
        }
        document.getElementById(t).style.zIndex = 3;
      } else if (i === '1') {
        let t = temp[i];
        if (t === 'new') {
          document.getElementById(t).style.transform =
            'translate(150px, -20px)';
        } else if (t === 'ori') {
          document.getElementById(t).style.transform = 'translate(0, -40px)';
        } else if (t === 'ani') {
          document.getElementById(t).style.transform =
            'translate(-150px, -20px)';
        } else if (t === 'meme') {
          document.getElementById(t).style.transform = 'translate(0px, 0px)';
        }
        document.getElementById(t).style.zIndex = 1;
      } else if (i === '2') {
        let t = temp[i];
        if (t === 'new') {
          document.getElementById(t).style.transform = 'translate(0px, 0px)';
        } else if (t === 'ori') {
          document.getElementById(t).style.transform =
            'translate(-150px, -20px)';
        } else if (t === 'ani') {
          document.getElementById(t).style.transform = 'translate(-300px, 0px)';
        } else if (t === 'meme') {
          document.getElementById(t).style.transform =
            'translate(-150px, 20px)';
        }
        document.getElementById(t).style.zIndex = 2;
      } else if (i === '3') {
        let t = temp[i];
        if (t === 'new') {
          document.getElementById(t).style.transform = 'translate(150px, 20px)';
        } else if (t === 'ori') {
          document.getElementById(t).style.transform = 'translate(0px, 0px)';
        } else if (t === 'ani') {
          document.getElementById(t).style.transform =
            'translate(-150px, 20px)';
        } else if (t === 'meme') {
          document.getElementById(t).style.transform = 'translate(0px, 40px)';
        }
        document.getElementById(t).style.zIndex = 4;
      }
    }

    temp = order;
    arrayRotate(temp, true);
    setOrder(temp);
  }

  function onNftPageClick(e) {
    let temp = order;
    let i;

    // console.log(e.target.parentNode);
    let id = e.target.id;
    if (e.target.id === '') {
      id = e.target.parentNode.id;
    }

    if (id === 'meme') {
      document.getElementById('inputForMeme').style.visibility = 'visible';
      document.getElementById('inputForMeme').style.opacity = '1';
      // setIsMeme(true);
    } else {
      document.getElementById('inputForMeme').style.visibility = 'hidden';
      document.getElementById('inputForMeme').style.opacity = '0';
      // setIsMeme(false);
    }

    setFourImages(id);

    for (i in order) {
      // console.log(id + ' ' + i);
      if (id === temp[i] && parseInt(i) >= 1) {
        // console.log(id + ' ' + i);
        let j = 4 - parseInt(i) - 1;
        // console.log(j);
        rotateRight();
        (function myLoop(k) {
          setTimeout(function () {
            // console.log(k);
            if (k > 0) {
              rotateRight();
              k -= 1;
              myLoop(k);
            }
          }, 2000);
        })(j);
        break;
      }
    }
  }

  async function handleGenerateNft() {
    if (isGenerateloading) return;

    console.log('handle', nftNummber);

    if (dropImageDesc === 'Cover') {
      setMsg('Select Prompt');
      setIsModalOpen(true);
      return;
    }
    setNftGenerated(true);
    if (dropImageDesc === 'Meme') {
      console.log('meme');
      await handleGenerateMeme();
      return;
    }
    if (dropImageDesc === 'Animation') {
      await handleGenerateAnimation();
      return;
    }

    // setDropImageDesc('Prompt');
    setIsGenerateLoading(true);
    await generateImage();
    setIsGenerateLoading(false);
  }
  async function putToCloud(file) {
    const pinataEndpoint = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
    const apiKey = 'b9ca64b8a604b3758a26';
    const apiSecret =
      '26b77f02a5a433330bc93c7ad023a0107d8b5636d4f09a47491c72680b6639b3';
    console.log('fetching');
    // Fetch the image data from the external site
    try {
      let data = new FormData();
      data.append('file', file);
      console.log('in upload img to ipfs', file, file.name);
      // const metadata = JSON.stringify({
      //   name: 'image_' + file.name,
      //   keyvalues: {
      //     exampleKey: 'exampleValue',
      //   },
      // });
      // data.append('pinataMetadata', metadata);

      return axios
        .post(pinataEndpoint, data, {
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            pinata_api_key: apiKey,
            pinata_secret_api_key: apiSecret,
          },
        })
        .then(function (response) {
          console.log('image uploaded pinata.js', response.data.IpfsHash);
          return {
            success: true,
            pinataURL: 'https://ipfs.io/ipfs/' + response.data.IpfsHash,
          };
        })
        .catch(function (error) {
          console.log(error);
          return {
            success: false,
            message: error.message,
          };
        });
      // .then((result) => {
      //   // Log the IPFS hash of the uploaded file
      //   console.log(result.data.IpfsHash);
      //   return 'https://ipfs.io/ipfs/' + result.data.IpfsHash;
      // })
      // .catch((error) => {
      //   console.error(error);
      // });
    } catch (error) {
      console.log('Error sending File to IPFS: ');
      console.log(error);
    }
  }
  async function generateImage() {
    // console.log('Clicked upload...', nftNummber);
    // if (nftNummber < 0 || nftNummber > 10000) {
    //   console.log("Can't Mint");
    //   return;
    // }
    console.log('Generate', dropBoxImageUrl);
    try {
      // adding padding
      // let inputImage ='blob:https://test.sorekt.wtf/10d18f13-dc96-4358-8d04-646b5d8e266a';
      let inputImage = uploadedFile;
      let response = await putToCloud(inputImage);
      if (response.success === true) {
        console.log('file uploaded to pinata...', response.pinataURL);
        inputImage = response.pinataURL;

        // console.log('Loading Padding ', inputImage);
        // response = await axios.post(
        //   // 'http://localhost:5200/openai/addPadding',
        //   'https://test.sorekt.indexx.ai/openai/addPadding',
        //   { inputImage: inputImage }
        // );
        // console.log('Padding response: ', response);
        // console.log('Padding : ', response.data.data);
        // // // return;
        // inputImage = response.data.data;
        const details = {
          image: inputImage,
          numberOfOutput: 4,
          size: '512x512',
        };
        console.log('Loading image generation');
        response = await axios.post(
          // 'http://localhost:5200/openai/imagevariation',
          'https://test.sorekt.indexx.ai/openai/imagevariation',
          details
        );
        console.log('Whole message,', response);
        console.log(response.data.data.data);
        let outputUrls = [];
        // console.log();
        for (let index = 0; index < details.numberOfOutput; index++) {
          console.log(response.data.data.data[index]);
          // console.log(response.data.url[index].data);
          outputUrls.push(response.data.data.data[index].url);
        }
        setGeneratedImageURL(outputUrls);
        setGeneratedPromptURL(outputUrls);
        console.log(outputUrls);
        // if (response.status !== 200) {
        //   console.log(response.error);
        // } else {
        //   // setReviewMintedPop(true);
        // }
        // });
      }
    } catch (error) {
      // alert('come back soon');
      setIsModalOpen(true);
      setMsg('Come back soon');
      console.log(error);
    }
    // console.log(response.data.url[0]);
  }
  async function generateMeme() {
    setIsGenerateLoading(true);
    try {
      const response = await axios.post(
        // 'http://localhost:5200/openai/generateMeme',
        'https://test.sorekt.indexx.ai/openai/generateMeme',
        { memeText: memeText }
      );
      console.log('Whole message,', response);
      console.log(response.data.data);
      console.log('gene', response.data.data[0].text);
      const generatedMemes = [];
      for (const iterator of response.data.data) {
        generatedMemes.push(iterator.text);
      }
      setGeneratedMemeText(generatedMemes);
    } catch (error) {
      setIsModalOpen(true);
      setMsg('Come back soon');
      console.log('Error', error);
    }
    setIsGenerateLoading(false);
  }
  async function handleGenerateMeme() {
    try {
      console.log('Meme: ', memeText);
      setMemeText(memeText.trim());
      if (memeText.trim().length < 1) {
        setIsModalOpen(true);
        setMsg('Enter meme Text');
        return;
      }
      await generateMeme();
    } catch (error) {}
  }
  async function generateAnimation() {
    setIsGenerateLoading(true);
    try {
      const response = await axios.post(
        // 'http://localhost:5200/openai/animateImages',
        'https://test.sorekt.indexx.ai/openai/animateImages',
        { imageUrls: generatedPromptURL, width: 512, height: 512 }
      );
      console.log('Whole message,', response);

      console.log(response.data.data);
      setSelectedAnimatedImage(response.data.data);
      // console.log('gene', response.data.data[0].text);
      const generatedMemes = [];
      for (const iterator of response.data.data) {
        generatedMemes.push(iterator.text);
      }
      console.log(generatedMemes);
      setGeneratedMemeText(generatedMemes);
    } catch (error) {
      setIsModalOpen(true);
      setMsg('Come back soon');
      console.log('Error', error);
    }
    setIsGenerateLoading(false);
  }
  async function handleGenerateAnimation() {
    console.log('Animation generation', generatedImageURL, generatedPromptURL);
    if (generatedPromptURL[0].includes('http')) {
      await generateAnimation();
    } else {
      setMsg('Kindly generate NFT before animation');
      setIsModalOpen(true);
    }
  }
  function handleImageClick(index) {
    console.log(index);
    console.log(generatedImageURL[index]);
    if (activeImage === 'prompt' && nftGenerated === true) {
      setSelectedPromptURL(generatedImageURL[index]);
    }
  }
  function handleMemeClick(index) {
    console.log('Meme clicked', index);
    console.log(generatedImageURL[index], generatedMemeText[index]);
    if (nftGenerated === true) {
      setSelectedMemeText(generatedMemeText[index]);
      setSelectedMemeImage(generatedImageURL[index]);
    }
  }
  function handleFileChange(e) {
    console.log(e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
    setUploadedFile(e.target.files[0]);
    // const selectedFilesArray = Array.from(e.target.files[0]);
    // console.log(selectedFilesArray);
    // console.log(file, typeof URL.createObjectURL(file));

    setDropBoxImageUrl(URL.createObjectURL(e.target.files[0]));
  }
  useEffect(() => {
    if (active === 'tile') {
      document.getElementById('inspression_tile_text').style.color = 'black';
      document.getElementById('inspression_rotate_text').style.color =
        'rgba(0, 0, 0, 0.5)';
    } else {
      document.getElementById('inspression_rotate_text').style.color = 'black';
      document.getElementById('inspression_tile_text').style.color =
        'rgba(0, 0, 0, 0.5)';
    }
  }, [active]);
  let dropImageOne, dropImageTwo, dropImageThree, dropImageFour;

  if (activeImage === 'meme') {
    dropImageOne = (
      <div
        className='inspression_drop_meme'
        onClick={() => {
          handleMemeClick(0);
        }}
      >
        <p style={{ fontSize: '25px' }}>{generatedMemeText.at(0)}</p>
        {nftGenerated ? (
          ''
        ) : (
          <img src={generatedImageURL.at(0)} alt='four_images' />
        )}
      </div>
    );
    dropImageTwo = (
      <div
        className='inspression_drop_meme'
        onClick={() => {
          handleMemeClick(1);
        }}
      >
        <p style={{ fontSize: '25px' }}>{generatedMemeText.at(1)}</p>
        {nftGenerated ? (
          ''
        ) : (
          <img src={generatedImageURL.at(1)} alt='four_images' />
        )}
      </div>
    );
    dropImageThree = (
      <div
        className='inspression_drop_meme'
        onClick={() => {
          handleMemeClick(2);
        }}
      >
        <p style={{ fontSize: '19.68px' }}>{generatedMemeText.at(2)}</p>
        {nftGenerated ? (
          ''
        ) : (
          <img src={generatedImageURL.at(2)} alt='four_images' />
        )}
      </div>
    );
    dropImageFour = (
      <div
        className='inspression_drop_meme'
        onClick={() => {
          handleMemeClick(3);
        }}
      >
        <p
          style={{
            fontSize: '22.36px',
          }}
        >
          {generatedMemeText.at(3)}
        </p>
        {nftGenerated ? (
          ''
        ) : (
          <img src={generatedImageURL.at(3)} alt='four_images' />
        )}
      </div>
    );
  } else {
    dropImageOne = (
      <img
        className='inspression_drop_images_normal_img'
        src={generatedImageURL.at(0)}
        alt='imageData'
        id='0'
        onClick={() => handleImageClick('0')}
      />
    );
    dropImageTwo = (
      <img
        className='inspression_drop_images_normal_img'
        alt='imageData'
        src={generatedImageURL.at(1)}
        id='1'
        onClick={() => handleImageClick('1')}
      />
    );
    dropImageThree = (
      <img
        className='inspression_drop_images_normal_img'
        src={generatedImageURL.at(2)}
        alt='imageData'
        id='2'
        onClick={() => handleImageClick('2')}
      />
    );
    dropImageFour = (
      <img
        className='inspression_drop_images_normal_img'
        src={generatedImageURL.at(3)}
        alt='imageData'
        id='3'
        onClick={() => handleImageClick('3')}
      />
    );
  }

  let drop = (
    <div className='inspression_drop'>
      <p className='inspression_drop_desc'>{dropImageDesc}</p>
      <div className='inspression_drop_images'>
        <div className='inspression_drop_images_no_right_border'>
          {dropImageOne}
        </div>
        <div className='inspression_drop_images_no_right_border'>
          {dropImageTwo}
        </div>
        <div className='inspression_drop_images_no_right_border'>
          {dropImageThree}
        </div>
        <div className='inspression_drop_images_normal_border'>
          {dropImageFour}
        </div>
      </div>

      <div className='ai_engine_free_drop_box'>
        <div className='ai_engine_free_drop_box_drop'>
          <img
            className='ai_engine_free_drop_box_drop_img'
            src={dropBoxImageUrl}
            alt='head_rotate_inspression'
          />
          <label>
            {dropBoxImageUrl.includes('http') ? (
              <></>
            ) : (
              <img
                className='ai_engine_free_drop_box_drop_plus'
                src={plus_ai_engine_free}
                alt='plus_ai_engine_free'
              />
            )}
            <input
              type='file'
              style={{ display: 'none', cursor: 'pointer' }}
              onChange={handleFileChange}
            ></input>
          </label>
        </div>

        <div className='ai_engine_free_drop_box_text'>
          <p>Drop or upload an image</p>
        </div>
      </div>
      <input
        id='inputForMeme'
        className='inspression_drop_input'
        placeholder='Type the description for the meme'
        onChange={(e) => {
          setMemeText(e.target.value);
        }}
      ></input>

      <div className='inspression_drop_buttons'>
        <div
          className='inspression_drop_buttons_generate'
          onClick={handleGenerateNft}
        >
          Generate NFT{' '}
          {isGenerateloading ? (
            <img style={{ height: '15px' }} src={loader_ai} alt='loader_ai' />
          ) : (
            <></>
          )}
        </div>
      </div>
      <center></center>
    </div>
  );
  let changing;

  if (active === 'rotate') {
    changing = (
      <div className='inspression_rotate_book'>
        <div className='inspression_rotate_book_img'>
          <div></div>
          <div></div>
          <div
            className='inspression_rotate_book_img_ori'
            onClick={onNftPageClick}
            id='ori'
          >
            <img
              style={{ width: '88.31px', height: '40.66px', marginTop: '13px' }}
              src={SoRekt_Black_1}
              alt='SoRekt_Black_1'
            />

            <img
              style={{ width: '155px', height: '38.18px', marginTop: '-12px' }}
              src={logo_ai}
              alt='logo_ai'
            />
            <img
              className='inspression_rotate_book_img_ori_nft'
              src={imageURL}
              alt='origianl_view'
            />
          </div>

          <img
            id='ani'
            className='inspression_rotate_book_img_ani'
            src={selectedAnimatedImage}
            alt='rotate_animation_ai'
            onClick={onNftPageClick}
          />
          <img
            id='new'
            className='inspression_rotate_book_img_new'
            src={selectedPromptURL}
            alt='rotate_prompt_ai'
            onClick={onNftPageClick}
          />
          <div
            className='inspression_rotate_book_img_meme'
            onClick={onNftPageClick}
            id='meme'
          >
            <p>{selectedMemeText}</p>
            <img
              className='inspression_rotate_book_img_meme_nft'
              src={selectedMemeImage}
              alt='rotate_meme_ai'
            />
          </div>
        </div>
      </div>
    );
  } else {
    changing = (
      <div className='inspression_tile'>
        <div className='inspression_tile_photos'>
          <div className='inspression_tile_photos_r1'>
            <div
              className='inspression_tile_photos_no_right_border'
              onClick={openTilePopUp}
              id='tileOriginal'
            >
              <img
                style={{
                  width: '50.69px',
                  height: '23.34px',
                  marginTop: '5px',
                }}
                src={SoRekt_Black_1}
                alt='SoRekt_Black_1_tile'
              />

              <img
                style={{
                  width: '88.96px',
                  height: '21.92px',
                  marginTop: '-5px',
                }}
                src={logo_ai}
                alt='logo_ai_tile'
              />
              <img
                style={{
                  width: '90px',
                  height: '92px',
                  marginTop: '2px',
                }}
                src={imageURL}
                alt='original_view_tile'
              />
            </div>
            <img
              id='tilePrompt'
              className='inspression_tile_photos_normal'
              src={selectedPromptURL}
              alt='rotate_prompt_inspression'
              onClick={openTilePopUp}
            />
          </div>
          <div className='inspression_tile_photos_r1'>
            <div
              id='tileMeme'
              className='inspression_tile_photos_no_right_top_border'
              onClick={openTilePopUp}
            >
              <p>{selectedMemeText}</p>
              {nftGenerated ? (
                ''
              ) : (
                <img src={selectedMemeImage} alt='rotate_meme_ai' />
              )}
            </div>

            <img
              id='tileAnimation'
              className='inspression_tile_photos_no_top_border'
              src={selectedAnimatedImage}
              alt='rotate_animation_ai'
              onClick={openTilePopUp}
            />
          </div>
        </div>
      </div>
    );
  }

  //   if (nftData.length === 0) {
  //     nftList = (
  //       <div className='inspression_nft_empty'>
  //         <p>You don't have SoRekt NFT</p>
  //         <div className='inspression_nft_empty_buy'>Buy NFT</div>
  //       </div>
  //     );
  //   } else {
  //     nftList = (
  //       <div className='inspression_nft-list'>
  //         {nftData.map((info) => (
  //           <AiNft
  //             src={info.src}
  //             key={info.number}
  //             name={info.name}
  //             desc1={info.desc1}
  //             desc2={info.desc2}
  //           />
  //         ))}
  //       </div>
  //     );
  //   }

  return (
    <div className='inspression'>
      <div className='inspression_build'>
        <SpinTokens
          tp='0'
          bt='20px'
          le='0'
          ri='910px'
          ht='82.29px'
          ht_dr='82.29px'
          src={home}
          src_dr={home_dark}
          name='home'
          name_dr='home_dr'
          link='/'
        />
        <img
          style={{ width: '235px', height: '57.89px' }}
          src={logo_ai}
          alt='logo_ai'
        />
        <p className='inspression_build_desc'>Build your SoRekt Ai NFT</p>
        <p className='inspression_build_descc'>Trial Version</p>
      </div>
      <div className='inspression_bar_book_drop'>
        <div className='inspression_bar_book'>
          <div className='inspression_bar'>
            <div className='inspression_bar_tile_rotate'>
              <div>
                <p id='inspression_tile_text' onClick={() => setActive('tile')}>
                  Tile
                </p>
              </div>
              <div>
                <p
                  id='inspression_rotate_text'
                  onClick={() => setActive('rotate')}
                >
                  Rotate
                </p>
              </div>
            </div>
          </div>
          {changing}
        </div>
        {drop}
      </div>

      <div className='inspression_how font_avenir'>
        <NavLink to='/how-to-use'>How to use</NavLink>
      </div>

      <PopUp
        trigger={tilePopUpTrigger}
        onClickOutsidePopUp={onClickOutsideTilePopUp}
        position='absolute'
        popAlign=''
      >
        {tilePopUpContent}
      </PopUp>

      <PopUp trigger={notYourNftPop} backDrop='blur(4px)'>
        <div
          className='inspression_not_nft_pop'
          ht='fit-content'
          wd='fit-content'
        >
          <img src={mask_arrow_ai} alt='mask_arrow_ai' />
          <p className='inspression_not_nft_pop_p1'>This is not your NFT</p>
          <p className='inspression_not_nft_pop_p2'>
            Key in the correct number of your <br /> SoRekt NFT
          </p>
          <div
            className='inspression_not_nft_pop_btn'
            onClick={() => setNotYourNftPop(false)}
          >
            {' '}
            Go Back
          </div>
        </div>
      </PopUp>
      <PopUp trigger={isModalOpen} backDrop='blur(4px)'>
        <div
          className='inspression_general_pop'
          ht='fit-content'
          wd='fit-content'
        >
          <img src={mask_arrow_ai} alt='mask_arrow_ai' />
          <div className='inspression_general_pop_text'>{msg}</div>

          <div
            className='inspression_general_pop_back_btn'
            onClick={() => setIsModalOpen(false)}
          >
            {' '}
            Go Back
          </div>
        </div>
      </PopUp>

      <PopUp trigger={reviewMintedPop} backDrop='blur(4px)'>
        <div
          className='inspression_review_minted_pop'
          ht='fit-content'
          wd='fit-content'
        >
          <img
            src={head_rotate_transparent_ai}
            alt='head_rotate_transparent_ai'
          />
          <p className='inspression_review_minted_pop_p1'>
            Review your minted NFT in Opensea
          </p>
          <div
            className='inspression_review_minted_pop_opensea_btn'
            onClick={() => setReviewMintedPop(false)}
          >
            Go to Opensea
          </div>
          <div
            className='inspression_review_minted_pop_back_btn'
            onClick={() => setReviewMintedPop(false)}
          >
            Go Back
          </div>
        </div>
      </PopUp>

      <Footer />
    </div>
  );
};

export default Inspression;
