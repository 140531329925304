import React from "react";
import "./Styles/NavBar.css";
import { NavLink } from "react-router-dom";
import {
  home,
  about,
  ai_engine,
  treasure_hunt,
  rule_book,
  club,
  roadmap,
  buy_nft,
  buy_token,
  lend_hand,
  lend_hand_dark,
  home_dark,
  about_dark,
  ai_engine_dark,
  treasure_hunt_dark,
  club_dark,
  roadmap_dark,
  buy_nft_dark,
  buy_token_dark,
  rule_book_dark,
} from "./Assets/Images/Index";

const NavBar = () => {
  return (
    <div className="nav-body">
      <nav className="nav-body__icons">
        <ul className="nav-body__icons__list">
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/">
                <img
                  class="nav-body__icons__list__div__img"
                  src={home}
                  alt="home"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={home_dark}
                  alt="home_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/buy-nft-offers">
                <img
                  class="nav-body__icons__list__div__img"
                  src={buy_nft}
                  alt="buy_nft"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={buy_nft_dark}
                  alt="buy_nft_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/buy-token">
                <img
                  class="nav-body__icons__list__div__img"
                  src={buy_token}
                  alt="buy_token"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={buy_token_dark}
                  alt="buy_token_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/roadmap">
                <img
                  class="nav-body__icons__list__div__img"
                  src={roadmap}
                  alt="roadmap"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={roadmap_dark}
                  alt="roadmap_dark"
                />
              </NavLink>
            </div>
          </li>
          {/* <li>
            <div className='nav-body__icons__list__div'>
              <NavLink to='/team'>
                <img
                  class='nav-body__icons__list__div__img'
                  src={team}
                  alt='team'
                />
                <img
                  class='nav-body__icons__list__div__hover'
                  src={team_dark}
                  alt='team_dark'
                />
              </NavLink>
            </div>
          </li> */}
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/rulebook">
                <img
                  class="nav-body__icons__list__div__img"
                  src={rule_book}
                  alt="rule_book"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={rule_book_dark}
                  alt="treasure_hunt_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/club">
                <img
                  class="nav-body__icons__list__div__img"
                  src={club}
                  alt="club"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={club_dark}
                  alt="club_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className='nav-body__icons__list__div'>
              <NavLink to='/ai-engine-free-version'>
                <img
                  class="nav-body__icons__list__div__img"
                  src={ai_engine}
                  alt="ai_engine"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={ai_engine_dark}
                  alt="ai_engine_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/treasure-hunt">
                <img
                  class="nav-body__icons__list__div__img"
                  src={treasure_hunt}
                  alt="treasure_hunt"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={treasure_hunt_dark}
                  alt="treasure_hunt_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/charity">
                <img
                  class="nav-body__icons__list__div__img"
                  src={lend_hand}
                  alt="lend_hand"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={lend_hand_dark}
                  alt="lend_hand_dark"
                />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="nav-body__icons__list__div">
              <NavLink to="/about">
                <img
                  class="nav-body__icons__list__div__img"
                  src={about}
                  alt="about"
                />
                <img
                  class="nav-body__icons__list__div__hover"
                  src={about_dark}
                  alt="about_dark"
                />
              </NavLink>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
