import React, { useEffect, useRef, useState } from "react";
import "./Styles/HomePage.css";
import {
  rule_book_dark,
  ai_engine_dark,
  roadmap_dark,
  club_dark,
  buy_token_dark,
  about_dark,
  home_bgd,
  buy_nft_spin,
  buy_nft_dark,
  lend_hand_dark,
  monkey_home,
  monkey_mask,
  monkey_sound_3,
  monkey_sound_1,
  monkey_sound_2,
  blur_gif,
  logo_ai,
  logo_ai_dark,
  about,
  buy_token,
  ai_engine,
  roadmap,
  club,
  rule_book,
  lend_hand,
} from "./Assets/Images/Index";
import SoRekt_Black from "./Assets/Images/SoRekt_Black.png";
import boat from "./Assets/Gif/boat.gif";
import me from "./Assets/Gif/ME.gif";
import discord from "./Assets/Gif/discord.gif";
import twitter from "./Assets/Gif/Twiter-02 copy.gif";
import { NavLink } from "react-router-dom";
import SpinTokens from "./SpinTokens";
import { Howl } from "howler";
import YouTube from "react-youtube";

var getYouTubeID = require("get-youtube-id");

const HomePage = () => {
  const [maskOpacity, setMaskOpacity] = useState("1");
  const [playing, setPlaying] = useState({ play: false, num: 0 });
  const url = getYouTubeID("https://youtu.be/nKZdQCrHEwE");
  const opts = {
    height: "390",
    width: "670",
    playerVars: {
      autoplay: 0,
    },
  };
  const song1Ref = useRef(
    new Howl({ src: monkey_sound_1, html5: true, loop: true })
  );
  const song1 = song1Ref.current;
  const song2Ref = useRef(
    new Howl({ src: monkey_sound_2, html5: true, loop: true })
  );
  const song2 = song2Ref.current;
  const song3Ref = useRef(
    new Howl({ src: monkey_sound_3, html5: true, loop: true })
  );
  const song3 = song3Ref.current;

  function alternateMask() {
    if (maskOpacity === "1") {
      document.getElementById("monkeyMask").style.opacity = "0";
      document.getElementById("monkeyFace").style.opacity = "1";
      setPlaying({ play: true, num: playing.num < 3 ? playing.num + 1 : 1 });
      setMaskOpacity("0");
    } else {
      document.getElementById("monkeyMask").style.opacity = "1";
      document.getElementById("monkeyFace").style.opacity = "0";
      setPlaying({ play: false, num: playing.num });
      setMaskOpacity("1");
    }
  }

  useEffect(() => {
    if (playing.play === true) {
      if (playing.num === 1) {
        song1.play();
      } else if (playing.num === 2) {
        song2.play();
      } else if (playing.num === 3) {
        song3.play();
      }
    } else {
      song1.stop();
      song2.stop();
      song3.stop();
    }
  }, [playing, song1, song2, song3]);

  return (
    <div className="home-page">
      <div className="home-page__title">
        <NavLink to="/about">
          <img src={SoRekt_Black} alt="sorekt" />
          <p>“Attitude is Everything”</p>
        </NavLink>
      </div>
      <div className="home-page_social_logo">
        <div className="home-page_social_logo_inspression">
          <div className="home-page_social_logo_inspression_hover">
            <NavLink to="/ai-engine-free-version">
              <img
                className="home-page_social_logo_inspression_img_hover"
                src={logo_ai}
                alt="logo_inspression"
              />
              <img
                className="home-page_social_logo_inspression_img_no_hover"
                src={logo_ai_dark}
                alt="logo_ai_dark"
              />
            </NavLink>
          </div>
        </div>
        <div className="home-page__social">
          <div className="home-page__social__col__boatdiv">
            <NavLink
              to="https://opensea.io/collection/sorekt-wtf"
              target="_blank"
            >
              <img src={boat} alt="boat" class="home-page__social__col__boat" />
            </NavLink>
          </div>
          <div className="home-page__social__col">
            <NavLink to="/coming-soon" target="_blank">
              <img
                src={blur_gif}
                alt="blur_gif"
                class="home-page__social__col__blur"
              />
            </NavLink>
          </div>
          <div className="home-page__social__col">
            <NavLink to="/coming-soon" target="_blank">
              <img src={me} alt="me" class="home-page__social__col__me" />
            </NavLink>
          </div>
          <div className="home-page__social__col">
            <NavLink to="https://discord.gg/y2BHK7nwyc" target="_blank">
              <img
                src={discord}
                alt="discord"
                class="home-page__social__col__discord"
              />
            </NavLink>
          </div>
          <div className="home-page__social__col">
            <NavLink to="https://twitter.com/sorektNFT" target="_blank">
              <img
                src={twitter}
                alt="twitter"
                class="home-page__social__col__twitter"
              />
            </NavLink>
          </div>
          <div className="home-page__social__col">
            <button
              className="home-page__social__col_btn"
              onClick={alternateMask}
            >
              <img
                src={monkey_home}
                alt="monkey_home"
                class="home-page__social__col__monkey"
                id="monkeyFace"
              />
              <img
                src={monkey_mask}
                alt="monkey_mask"
                class="home-page__social__col__monkey-mask"
                id="monkeyMask"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="home-page__group1">
        <img src={home_bgd} alt="home_bgd" class="home-page__group1__art" />
        <SpinTokens
          tp="0"
          bt="500px"
          le="0"
          ri="1050px"
          ht="115px"
          ht_dr="115px"
          tranform="rotate(20deg)"
          src={about}
          src_dr={about_dark}
          name="about"
          name_dr="about_dr"
          link="/about"
        />

        <SpinTokens
          tp="0"
          bt="480px"
          le="650px"
          ri="600px"
          ht="150px"
          ht_dr="115px"
          src={buy_nft_spin}
          src_dr={buy_nft_dark}
          name="buy_nft"
          name_dr="buy_nft_dr"
          link="/buy-nft-offers"
        />

        <SpinTokens
          tp="0"
          bt="100px"
          le="890px"
          ri="0"
          ht="115px"
          ht_dr="115px"
          src={buy_token}
          src_dr={buy_token_dark}
          name="buy_token"
          name_dr="buy_token_dr"
          link="/buy-token"
        />

        <SpinTokens
          tp="0"
          bt="580px"
          le="940px"
          ri="0"
          ht="115px"
          ht_dr="115px"
          src={ai_engine}
          src_dr={ai_engine_dark}
          name="ai_engine"
          name_dr="ai_engine_dr"
          link="/ai-engine-free-version"
        />

        <SpinTokens
          tp="0"
          bt="50px"
          le="0"
          ri="500px"
          ht="115px"
          ht_dr="115px"
          src={roadmap}
          src_dr={roadmap_dark}
          name="road_map"
          name_dr="road_map_dr"
          link="/roadmap"
        />

        {/* <SpinTokens
          tp='0'
          bt='90px'
          le='500px'
          ri='0'
          ht='115px'
          ht_dr='115px'
          src={story}
          src_dr={story_dark}
          name='story'
          name_dr='story_dr'
          link='/ai-engine'
        /> */}

        <SpinTokens
          tp="330px"
          bt="0"
          le="0"
          ri="680px"
          ht="115px"
          ht_dr="115px"
          src={club}
          src_dr={club_dark}
          name="club"
          name_dr="club_dr"
          link="/club"
        />

        <SpinTokens
          tp="370px"
          bt="0"
          le="30px"
          ri="0"
          ht="115px"
          ht_dr="115px"
          src={rule_book}
          src_dr={rule_book_dark}
          name="rule_book"
          name_dr="rule_book_dr"
          link="/rulebook"
        />

        <SpinTokens
          tp="300px"
          bt="0"
          le="850px"
          ri="0"
          ht="115px"
          ht_dr="115px"
          src={lend_hand}
          src_dr={lend_hand_dark}
          name="lend_hand"
          name_dr="lend_hand_dr"
          link="/charity"
        />
      </div>
      <div className="home-page_video" style={{ textAlign: "center" }}>
        <YouTube videoId={url} opts={opts} />
        <p class="home-page__rights">All Rights Reserved 2023</p>
      </div>
    </div>
  );
};

export default HomePage;
