import { isChrome, isEdge, isFirefox, isIE, isOpera } from "react-device-detect";
import SoRektAddress from './Backend/ContractData/SoRekt-address.json';
import SoRektAbi from './Backend/ContractData//SoRekt.json';
const ethers = require('ethers');


function findBrowserAndSetNavigation(setNavigation) {
  // console.log("Browser: ");
  // console.log(browserName, browserVersion);
  // console.log(isFirefox, isEdge, isSafari, isChrome, isIE);
  if (isFirefox) setNavigation("https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/");
  else if (isEdge || isIE) setNavigation("https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US");
  else if (isChrome) setNavigation("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn");
  else if (isOpera) setNavigation("https://addons.opera.com/en-gb/extensions/details/metamask-10/");
  // else if(isBrave)
  else setNavigation("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn");
}
export async function web3Handler(setAccount,setProvider,setMsg,setIsModalOpen,setNavigation,setSoRekt){
    console.log(setSoRekt);
  if (window.ethereum && window.ethereum.isMetaMask && !window.ethereum.isBraveWallet) {
    try {
      // const x = await handleSwitchNetwork();
      // if (x !== 1) {
      //   alert("Switch network");
      //   return;
      // }

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccount(accounts[0]);

      // Get provider from Metamask
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // console.log(provider);
      setProvider(provider);
      // Set signer
      const signer = provider.getSigner();

      window.ethereum.on("chainChanged", (chainId) => {
        // window.location.reload();
      });

      window.ethereum.on("accountsChanged", async function (accounts) {
        setAccount(accounts[0]);
        await web3Handler(); 
      });
      loadContracts(signer,setSoRekt);
    } catch (err) {
      console.log(err);
    }
  } else {
    // alert('Metamask not installed or conflicted with other wallets');
    // setTitle("Oops!");
    setMsg("Yo, you ain't got Metamask installed, bro. Gotta get that sh*t first to proceed.");
    setIsModalOpen(true);
    findBrowserAndSetNavigation(setNavigation);
    // setButtonText("Install Metamask");
    return;
  }
};
const loadContracts = async (signer,setSoRekt) => {
  const soRekt = new ethers.Contract(SoRektAddress.address, SoRektAbi.abi, signer);
  setSoRekt(soRekt);
  console.log(soRekt);
  let symbol = await soRekt.symbol();
  console.log(symbol);
  // console.log("Aftet");
};
